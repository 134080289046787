<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col>
        <h1 style="font-size: 24px; font-weight: bold;">Policies</h1>
        <p>Explore policy topics across countries</p>
      </b-col>
      <b-col v-if="permissions.addInitiative">
        <b-form inline>
          <b-button class="mr-2" v-if="permissions.addPolicy" variant="outline-primary" :to="{ name: 'FormNew', params: { model: 'impactpolicy' }}">new policy</b-button>
          <b-button class="mr-2" v-if="permissions.addInitiative" variant="outline-primary" :to="{ name: 'FormNew', params: { model: 'impactinitiative' }}">new initiative</b-button>
        </b-form>
      </b-col>
    </b-row>
    <b-row class="mb-4 shadow">
      <b-col lg="3" class="py-3 px-0" style="background: white; height: 60vh; overflow-y: auto;  overflow-x: auto;">
        <div>
            <div class="mb-5" v-for="(row, index) in policiesByArea" :key="'topic-' + index">
              <h5 class="px-3">{{row.area}}</h5>
              <div class="px-3 py-3" style="cursor: pointer;" v-for="(policy, i) in row.policies" :key="'policy-' + i" :id="'policy'+policy.id" @click="findInitiatives(policy)">
                {{policy.name}}
              </div>
            </div>
        </div>
      </b-col>
      <b-col lg="2" v-if="showInitiatives" class="py-3 px-0" style="background: #e6f9ff; height: 60vh; overflow-y: auto;  overflow-x: auto;">
          <div class="px-3 py-3" style="cursor: pointer;" v-for="(initiative, index) in initiatives" :key="'initiative-' + index" :id="'initiative'+initiative.id" @click="findInitiative(initiative)">
            <div>
              <div>{{initiative.country}}</div>
            </div>
          </div>
      </b-col>
      <b-col lg="7" v-if="initiative" class="py-3" style="background: white; height: 60vh; overflow-y: auto;  overflow-x: auto;">
        <b-container>
          <b-row>
            <b-col>
              <h5 v-if="$config.BUILD === 'full'"><b-link :to="{ name: 'ImpactInitiative', params: { id: initiative.id } }">{{ initiative.name }}</b-link></h5>
              <h5 v-else>{{ initiative.name }}</h5>
              <div><b-link :to="{ name: 'ImpactPoliciesCountry', params: { country: initiative.country } }">{{ initiative.country }}</b-link></div>
              <div v-if="$config.BUILD === 'full'"><b-link :to="{ name: 'ImpactPoliciesPolicy', params: { id: policy.id } }">{{ policy.name }} policy page</b-link></div>
              <div v-if="initiative.impactexperts.length > 0">
              <div class="mt-4"><strong>Specialists</strong></div>
              <div v-for="expert in initiative.impactexperts" :key="'expert'+expert.id">
                <div v-if="$config.BUILD === 'full'">
                  <router-link :to="{ name: 'Expert', params: { id: expert.id }}">{{expert.name}}</router-link>
                </div>
                <div v-else>{{expert.name}}</div>
              </div>
              </div>
              <div class="my-4"><strong>{{ initiative.headline }}</strong></div>
              <div v-html="initiative.description"></div>
              <div><small>last update: {{ moment(initiative.updated_at).format('YYYY-MM-DD') }}</small></div>
            </b-col>
            <b-col>
              <policy-initiative-details :item="initiative" v-if="!initiative.impactpolicy.overview" />
              <div v-if="initiative.impactinitiativeslinks.length > 0">
                <div class="mt-5"><strong>External links</strong></div>
                <div v-for="link in initiative.impactinitiativeslinks" :key="'link'+link.id" class="my-2">
                  <div><a :href="link.url" target="_blank">{{link.name}}</a></div>
                </div>
              </div>
              <div v-if="!loadingNews && news.length > 0">
                <div class="mt-5"><strong>News - external links</strong></div>
                <div v-for="item in news" :key="'news'+item.id" class="my-3">
                  <div><a :href="item.link">{{item.name}}</a></div>
                  <div><small>{{item.publisher}} - {{moment(item.date).format('YYYY-MM-DD')}}</small></div>
                </div>
              </div>
              <div class="my-3" v-if="loadingNews">
                <b-spinner label="checking news..." />
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import delay from '@/libs/delay'
import ac from '@/libs/accesscontrol'
import PolicyInitiativeDetails from '@/components/PolicyInitiativeDetailsRows'

export default {
  name: 'PoliciesList',
  components: {
    PolicyInitiativeDetails
  },
  computed: {
    areas: function () {
      const policiesAll = this.policiesAll
      let results = ['General']
      policiesAll.forEach(policy => {
        if (policy.taxonomy.length > 0) {
          policy.taxonomy.forEach(taxonomy => {
            results.push(taxonomy.name)
          })
        }
      })
      results = _.uniq(results)
      results.sort()
      return results
    },
    initiativesAll: function () {
      return this.$store.state.initiativesPolicies
    },
    policies: function () {
      return this.$store.getters.policiesShow
    },
    policiesAll: function () {
      return this.$store.state.policies
    },
    policiesByArea: function () {
      const results = []
      const all = this.policies
      this.areas.forEach(area => {
        let row = {}
        row = {
          area: area,
          policies: []
        }
        all.forEach(policy => {
          if (policy.taxonomy.length > 0) {
            policy.taxonomy.forEach(t => {
              if (t.name === area) {
                row.policies.push(policy)
              }
            })
          } else {
            if (area === 'General') {
              row.policies.push(policy)
            }
          }
        })
        if (row.policies.length > 0) {
          results.push(row)
        }
      })
      results.forEach(row => {
        const policies = _.orderBy(row.policies, ['overview', 'name'], ['desc', 'asc'])
        row.policies = policies
      })
      return results
    },
    policiesByTopic: function () {
      const results = []
      const all = this.policies
      this.topics.forEach(topic => {
        let row = {}
        row = {
          topic: topic,
          policies: []
        }
        all.forEach(policy => {
          if (policy.topics.length > 0) {
            policy.topics.forEach(t => {
              if (t.name === topic) {
                row.policies.push(policy)
              }
            })
          } else {
            if (topic === 'General') {
              row.policies.push(policy)
            }
          }
        })
        if (row.policies.length > 0) {
          results.push(row)
        }
      })
      return results
    },
    topics: function () {
      const policiesAll = this.policiesAll
      let results = ['General']
      policiesAll.forEach(policy => {
        if (policy.topics.length > 0) {
          policy.topics.forEach(topic => {
            results.push(topic.name)
          })
        }
      })
      results = _.uniq(results)
      results.sort()
      return results
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    document.title = "Climate Policy and Regulatory Dashboard - Policies"
    this.$stat.log({ page: 'impactpolicies list', action: 'open impactpolicies list' })
    this.permissions.addInitiative = ac.can(this.user.acgroups).createAny('impactinitiative').granted
    this.permissions.addPolicy = ac.can(this.user.acgroups).createAny('impactpolicy').granted
    if (this.$route.params.id) {
      this.findInitiativeOnLoad(this.$route.params.id)
    }
  },
  data () {
    const data = {
      initiative: null,
      initiatives: [],
      loadingNews: false,
      moment: moment,
      news: [],
      permissions: {
        addInitiative: false,
        addPolicy: false
      },
      policy: null,
      showInitiatives: false
    }
    return data
  },
  methods: {
    findInitiativeOnLoad: async function (id) {
      this.initiative = _.find(this.initiativesAll, { id: parseInt(id) })
      this.policy = _.find(this.policiesAll, { id: this.initiative.impactpolicy_id })
      this.initiatives = _.sortBy(_.filter(this.initiativesAll, { impactpolicy_id: this.initiative.impactpolicy_id }), ['country'])
      this.showInitiatives = true
      this.loadNews()
      await delay(20)
      document.getElementById('policy' + this.policy.id).setAttribute('style', 'background-color: #aceaff; font-weight: bold; cursor: normal;')
      document.getElementById('policy' + this.policy.id).scrollIntoView({ behavior: 'smooth', block: 'center' })
      document.getElementById('initiative' + this.initiative.id).setAttribute('style', 'background-color: #aceaff; font-weight: bold; cursor: normal;')
      document.getElementById('initiative' + this.initiative.id).scrollIntoView({ behavior: 'smooth', block: 'center' })
    },
    findInitiative: function (initiative) {
      if (this.initiative) {
        document.getElementById('initiative' + this.initiative.id).setAttribute('style', 'background-color: #e6f9ff; cursor: pointer; font-weight: normal;')
      }
      this.initiative = initiative
      document.getElementById('initiative' + this.initiative.id).setAttribute('style', 'background-color: #aceaff; font-weight: bold;')
      console.log(this.initiative)
      this.loadNews()
      this.$router.push({ name: 'ImpactPolicies', params: { id: initiative.id } })
    },
    findInitiatives: function (policy) {
      if (this.policy) {
        document.getElementById('policy' + this.policy.id).setAttribute('style', 'background-color: white; cursor: pointer; font-weight: normal;')
      }
      if (this.initiative) {
        document.getElementById('initiative' + this.initiative.id).setAttribute('style', 'background-color: #e6f9ff; font-weight: normal;')
      }
      this.initiative = null
      this.showInitiatives = false
      this.policy = policy
      this.initiatives = _.sortBy(_.filter(this.initiativesAll, { impactpolicy_id: policy.id }), ['country'])
      document.getElementById('policy' + this.policy.id).setAttribute('style', 'background-color: #aceaff; font-weight: bold; cursor: normal;')
      this.showInitiatives = true
      this.$router.push({ name: 'ImpactPolicies' })
    },
    loadNews: async function () {
      this.loadingNews = true
      try {
        document.title = this.initiative.name + ' - ' + this.initiative.country
        this.news = await this.$Amplify.API.get('cosmos', `/news/country/${this.initiative.country}/policy/${this.initiative.impactpolicy_id}`)
        this.loadingNews = false
      } catch (e) {
        this.$logger.warn(e)
      }
    }
  }
}
</script>

<style>
</style>
